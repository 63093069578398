<template>
  <base-section id="complain">
    <div
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false"
      data-aos-anchor-placement="top-center"
    >
      <base-section-heading color="grey lighten-2" title="Pengaduan Konsumen" />
      <v-container class="text-justify">
        <p>
          <v-img
            :src="require('@/assets/pengaduan-konsumen.jpg')"
            max-width="95%"
          />
        </p>
      </v-container>
    </div>
  </base-section>
</template>
<script>
export default {
  name: "SectionComplain",
};
</script>
